<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="500"
        scrollable
    >
      <form @submit.prevent="save">
        <v-card :loading="isLoading" :disabled="isLoading">

          <v-card-title>
            {{ title }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-4">

            <v-row>
              <v-col>
                <v-text-field label="Nom complet *"
                              hide-details="auto"
                              :error-messages="errors.name"
                              v-model="form.name"/>
              </v-col>
              <v-col>
                <v-text-field label="Téléphone *"
                              hide-details="auto"
                              :error-messages="errors.phone"
                              v-model="form.phone"/>
              </v-col>
            </v-row>


            <v-row>
              <v-col>
                <v-text-field label="Email *"
                              :error-messages="errors.email"
                              v-model="form.email"/>
              </v-col>
              <v-col>
                <v-file-input label="Image"
                              ref="image"
                              :error-messages="errors.photo"
                              @change="uploadImage"/>
              </v-col>
            </v-row>


            <v-text-field label="Nom d'utilisateur *"
                          :error-messages="errors.username"
                          v-model="form.username"/>

            <v-text-field label="Mot de pass *"
                          :error-messages="errors.password"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text':'password'"
                          @click:append="show = !show"
                          v-model="form.password"/>

          </v-card-text>

          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :disabled="isLoading"
                   :loading="btnLoading"
                   depressed
                   type="submit">
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>

        </v-card>
      </form>

    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      title: null,
      isLoading: false,
      btnLoading: false,
      show: false,

      errors: {},
      form: {
        id: '',
        name: '',
        phone: '',
        email: '',
        username: '',
        password: '',
        photo: '',
      },
    }
  },
  methods: {
    open(item) {
      if (item) {
        this.title = 'Modifier un store'
        this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          email: item.email,
          username: item.username,
          password: '',
          photo: ''
        }
      } else {
        this.title = 'Ajouter un store'
        this.form.id = ''
        this.form.photo = ''
        this.form.name = ''
        this.form.phone = ''
        this.form.email = ''
        this.form.username = ''
        this.form.password = ''
        if (this.$refs.image) {
          this.$refs.image.reset()
        }
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    uploadImage(file) {
      this.form.photo = file
    },
    save() {
      this.errors = {}
      this.isLoading = true
      this.btnLoading = true

      let methode = this.form.id ? 'update' : 'store'

      let formData = new FormData()
      formData.append('id', this.form.id)
      formData.append('name', this.form.name)
      formData.append('phone', this.form.phone)
      formData.append('email', this.form.email)
      formData.append('username', this.form.username)
      formData.append('password', this.form.password)
      formData.append('photo', this.form.photo)

      HTTP.post('/v1/partner-vouchers/stores/' + methode, formData).then(() => {
        this.isLoading = false
        this.btnLoading = false
        this.$successMessage = 'Ce store a été enregistré avec succès.'
        this.close()
        this.$emit('refresh')
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.isLoading = false
        this.btnLoading = false
        console.log(err)
      })
    },
  },
}
</script>

<style>
</style>