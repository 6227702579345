<template>
  <div>
    <v-card :min-height="$vuetify.breakpoint.mobile ? '' :'400'" flat>

      <v-card-text class="pa-0">

        <div v-if="isLoading">
          <v-skeleton-loader type="list-item-avatar"/>
          <v-skeleton-loader type="list-item-avatar"/>
          <v-skeleton-loader type="list-item-avatar"/>
          <v-skeleton-loader type="list-item-avatar"/>
        </div>

        <v-list v-else nav rounded>


          <v-list-item v-if="can('supplier')"
                       color="primary" to="/partner-vouchers/stores">
            <v-list-item-avatar>
              <v-icon>mdi-shopping-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Gestion des boutiques</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="can('supplier')"
                       color="primary" to="/partner-vouchers/statistics">
            <v-list-item-avatar>
              <v-icon>mdi-chart-pie</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Statistiques globale</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="(store,i) in $store.state.stores"
                       :key="i"
                       :to="'/partner-vouchers/store/'+store.id"
                       color="primary">

            <v-list-item-avatar v-if="store.account && store.account.photo">
              <v-img
                  :src="getImageLink(store.account.photo)"></v-img>
            </v-list-item-avatar>

            <v-list-item-avatar color="primary" v-else>
              <v-icon color="white">mdi-storefront-outline</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ store.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>

      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      fileUrl: process.env.VUE_APP_FILE_URL,
    }
  },
  methods: {
    getStores() {
      this.isLoading = true
      HTTP.get('/v1/partner-vouchers/stores').then(res => {
        this.isLoading = false
        let stores = res.data.data
        this.$store.commit('stores', stores)
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },

  created() {
    if (this.$store.state.stores.length === 0) {
      this.getStores()
    }
  },
}
</script>

<style scoped>

</style>