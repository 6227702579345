<template>
  <div class="text-center pa-2">
    <v-avatar tile :size="size ? size : 250">
      <v-img :src="image ? image : imageSrc"></v-img>
    </v-avatar>
    <h3 class="text-uppercase"> Pas de données</h3>
    <p class="my-2">
      Aucune donnée disponible
    </p>

    <v-btn rounded
           dark
           @click="reload"
           color="gifty"
           depressed
           class="my-3 transform-none">
      <v-icon left>mdi-refresh</v-icon>
      Actualiser
    </v-btn>

  </div>
</template>

<script>
export default {
  props: ['size', 'image', 'text'],
  data() {
    return {
      imageSrc: require('@/assets/no-transaction.svg'),
    }
  },
  methods: {
    reload() {
      this.$emit('reload')
    }
  }
}
</script>

<style scoped>

</style>